<template>
  <div
    class="regular-text title-with-highlights"
    :class="[themeColorClass, textColorClass]"
    :style="cssAlignment"
  >
    <UiHeader
      v-if="header"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :header-link="headerLink"
      :theme-color="themeColor"
    />

    <T3HtmlParser :content="bodytext" />

    <MacoButton
      :button="button"
      :lang="lang"
    />

    <div
      v-if="checkboxLogo"
      class="title-with-highlights__icon-wrapper"
    >
      <LogoSmall />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { MacoButton, UiHeader } from '~ui/components'
import useHeaderAlignment from '~ui/composables/useHeaderAlignment'
import useTheming from '~ui/composables/useTheming'
import type { UiCeTextProps } from '~ui/types'

const props = defineProps<UiCeTextProps>()

const themeColorClass = useTheming(props.themeColor)
const cssAlignment = useHeaderAlignment(props)

const textColorClass = computed(() => {
  return props.checkboxColor ? 'title-with-highlights--white-font' : ''
})
</script>

<style lang="scss">
.title-with-highlights {
  &--white-font :is(h1, h2, h3, h4, h5, h6) {
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 0;

    strong {
      font-weight: 700;
      color: color(white);
    }
  }

  &__icon-wrapper {
    margin-top: rem(18px);
  }
}
</style>
